import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_FooterObject } from "../../../../interfaces/footerObject.interface";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "../../../atoms/Icon/Icon.component";
import { DA_Accordion } from "../../../molecules/Accordion/Accordion.component";
import { _FooterLink } from "../FooterLink/FooterLink.component";
import S from "./MobileFooter.module.scss";

export interface DA_MobileFooterProps {
    data: DA_FooterObject;
}

export const DA_MobileFooter = ({ data }: DA_MobileFooterProps) => (
    <footer className={S.mobileFooter}>
        {data?.columns?.map((column, index) => (
            <DA_Accordion
                key={index}
                className={S.accordion}
                title={column.header}
                showToggleIcons
                animation
            >
                {column?.items?.map((item, index) => {
                    if (item?.itemType === "text") {
                        return (
                            <div key={index} className={S.footerText}>
                                <span className={S.itemTitle}>{item.title}</span>
                                <span dangerouslySetInnerHTML={{ __html: item.text }} />
                            </div>
                        );
                    }
                    if (item?.itemType === "link") {
                        return <_FooterLink key={index} {...item} classname={S.footerLink} />;
                    }
                })}
            </DA_Accordion>
        ))}

        {data?.socials?.map((social, index) => (
            <div key={index} className={cn(S.mobileFooterItem, S.noBorderBottom)}>
                <span className={S.mobileItemTitle}>{social.header}</span>
                <div className={S.soMeLinks}>
                    {social?.items?.map((item) => {
                        const iconName =
                            item.type === "facebook"
                                ? DA_IconNames.Facebook
                                : item.type === "linkedin"
                                ? DA_IconNames.LinkedIn
                                : item.type === "instagram"
                                ? DA_IconNames.Instagram
                                : item.type === "youtube"
                                ? DA_IconNames.YouTube
                                : DA_IconNames.Question;

                        return (
                            <DA_BasicLink
                                href={item.href}
                                key={item.type}
                                className={S.soMeLink}
                                ariaLabel={item.type}
                            >
                                <DA_Icon name={iconName} className={S.soMeIcon} useSvgDimensions />
                            </DA_BasicLink>
                        );
                    })}
                </div>
            </div>
        ))}
    </footer>
);
