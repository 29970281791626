import React, { forwardRef, LegacyRef, MouseEvent, Ref } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import S from "./Button.module.scss";

export enum DA_ButtonColor {
    White = "White",
    Action = "Action",
    Alert = "Alert",
    Grey = "Grey",
    LightGrey = "LightGrey",
    Primary = "Primary",
    Accent = "Accent",
}

interface ButtonPropsBase {
    title?: string;
    className?: string;
    color?: DA_ButtonColor;
    icon?: DA_IconNames;
    isGhost?: boolean;
    largeText?: boolean;
    small?: boolean;
    large?: boolean;
    hideText?: boolean;
    noWrap?: boolean;
    iconRight?: boolean;
    showSpinner?: boolean;
    fullWidth?: boolean;
    form?: string;
    disabled?: boolean;
}

interface ButtonWithOnClick extends ButtonPropsBase {
    onClick: (event?: MouseEvent<HTMLButtonElement>) => void;
    href?: never;
    target?: never;
    submit?: never;
    conventionalRouting?: never;
}

interface ButtonWithSubmit extends ButtonPropsBase {
    onClick?: never;
    href?: never;
    target?: never;
    submit: true;
    conventionalRouting?: never;
}

interface ButtonWithHref extends ButtonPropsBase {
    onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
    href: string;
    target?: "_blank" | "_self";
    submit?: never;
    conventionalRouting?: boolean;
}

export type DA_ButtonProps = ButtonWithOnClick | ButtonWithSubmit | ButtonWithHref;

export const DA_Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, DA_ButtonProps>(
    (props, ref) => {
        const {
            title,
            onClick,
            color,
            className,
            icon,
            href,
            target,
            isGhost,
            largeText,
            small,
            large,
            hideText,
            fullWidth,
            disabled,
            noWrap,
            form,
            submit,
            iconRight,
            conventionalRouting,
            showSpinner,
        } = props;

        const buttonClass = cn(
            S.button,
            largeText && S.largeText,
            small && S.small,
            large && S.large,
            isGhost && S.ghost,
            iconRight && S.iconRight,
            noWrap && S.noWrap,
            icon && S.hasIcon,
            icon && (!title || hideText) && S.iconOnly,
            fullWidth && S.fullWidth,
            disabled && S.disabled,
            (color === DA_ButtonColor.Action || (!color && !isGhost)) && S.action,
            color === DA_ButtonColor.White && S.white,
            (color === DA_ButtonColor.Grey || (!color && isGhost)) && S.grey,
            color === DA_ButtonColor.LightGrey && S.lightGrey,
            color === DA_ButtonColor.Primary && S.primary,
            color === DA_ButtonColor.Accent && S.acent,
            color === DA_ButtonColor.Alert && S.alert,
            className
        );

        // Link

        if (href) {
            return (
                <DA_BasicLink
                    ref={ref as Ref<HTMLAnchorElement>}
                    href={href}
                    target={target}
                    className={buttonClass}
                    onClick={onClick as never}
                    conventionalRouting={conventionalRouting}
                    ariaLabel={hideText && title}
                >
                    {/** Spinner - No Icon */}
                    {showSpinner && !icon && (
                        <span className={S.spinner}>
                            <DA_Spinner />
                        </span>
                    )}

                    <span className={S.content}>
                        {/** Icon - No Spinner */}
                        {icon && !showSpinner ? (
                            <DA_Icon name={icon} className={S.icon} />
                        ) : (
                            icon && showSpinner && <DA_Spinner className={S.icon} />
                        )}

                        {/** Title */}
                        {title && !hideText && <span className={S.title}>{title}</span>}
                    </span>
                </DA_BasicLink>
            );
        }

        // Button
        else {
            return (
                <button
                    ref={ref as LegacyRef<HTMLButtonElement>}
                    className={buttonClass}
                    type={submit ? "submit" : "button"}
                    onClick={onClick}
                    form={form}
                    disabled={disabled || showSpinner}
                    aria-label={hideText && title}
                >
                    {/** Spinner - No Icon */}
                    {showSpinner && !icon && (
                        <span className={S.spinner}>
                            <DA_Spinner />
                        </span>
                    )}

                    <span className={S.content}>
                        {/** Icon - No Spinner */}
                        {icon && !showSpinner ? (
                            <DA_Icon name={icon} className={S.icon} />
                        ) : (
                            icon && showSpinner && <DA_Spinner className={S.icon} />
                        )}

                        {/** Title */}
                        {title && !hideText && <span className={S.title}>{title}</span>}
                    </span>
                </button>
            );
        }
    }
);

DA_Button.displayName = "DA_Button";
