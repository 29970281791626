import React from "react";
// Import flags
import { ReactComponent as FlagUnknown } from "@danishagro/shared/src/assets/svg/flags/unknown.svg";
import { ReactComponent as FlagBG } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_BG.svg";
import { ReactComponent as FlagBR } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_BR.svg";
import { ReactComponent as FlagCN } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_CN.svg";
import { ReactComponent as FlagCZ } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_CZ.svg";
import { ReactComponent as FlagDE } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_DE.svg";
import { ReactComponent as FlagDK } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_DK.svg";
import { ReactComponent as FlagEE } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_EE.svg";
import { ReactComponent as FlagEN } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_EN.svg";
import { ReactComponent as FlagES } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_ES.svg";
import { ReactComponent as FlagFI } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_FI.svg";
import { ReactComponent as FlagHU } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_HU.svg";
import { ReactComponent as FlagLT } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_LT.svg";
import { ReactComponent as FlagLV } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_LV.svg";
import { ReactComponent as FlagNO } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_NO.svg";
import { ReactComponent as FlagPL } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_PL.svg";
import { ReactComponent as FlagPY } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_PY.svg";
import { ReactComponent as FlagSE } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_SE.svg";
import { ReactComponent as FlagUA } from "@danishagro/shared/src/assets/svg/flags/flagsFromRecivedFromDA/Flag_UA.svg";
// ... other flag imports

export const getFlagComponent = (cultureCode: string) => {
    switch (cultureCode) {
        case "bg-bg":
            return <FlagBG />;
        case "pt-br":
            return <FlagBR />;
        case "zh-cn":
            return <FlagCN />;
        case "cs-cz":
            return <FlagCZ />;
        case "de-de":
            return <FlagDE />;
        case "da-dk":
            return <FlagDK />;
        case "et-ee":
            return <FlagEE />;
        case "en-gb":
            return <FlagEN />;
        case "es-es":
            return <FlagES />;
        case "fi-fi":
            return <FlagFI />;
        case "hu-hu":
            return <FlagHU />;
        case "lt-lt":
            return <FlagLT />;
        case "lv-lv":
            return <FlagLV />;
        case "nb-no":
            return <FlagNO />;
        case "pl-pl":
            return <FlagPL />;
        case "es-py":
            return <FlagPY />;
        case "sv-se":
            return <FlagSE />;
        case "uk-ua":
            return <FlagUA />;
        default:
            return <FlagUnknown />;
    }
};
