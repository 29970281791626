import React from "react";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { Position } from "../../JobListPage.interface";
// import style
import S from "./Card.module.scss";

const getRelativePathFromUrl = (url) => {
    if (typeof url !== "string" || url.trim() === "") return "";
    const urlObj = new URL(url);
    return urlObj.pathname + urlObj.search;
};

export const Card = ({ id, name, department, city, country }: Position) => {
    const url = globalThis.siteSettingsObj?.jobPositionDetailPage?.url.replace("{positionID}", id);
    const relativePath = getRelativePathFromUrl(url);

    const { getDictionaryString } = useTranslations();

    return (
        <div className={S.jobListCard}>
            <DA_Title h3 noMargin>
                {name}
            </DA_Title>

            <DA_Text noPadding html={department.name} />

            {(city || country) && (
                <span className={S.addressInfo}>
                    <DA_Text
                        noPadding
                        html={city && country ? `${city}, ${country}` : city || country}
                    />
                </span>
            )}

            <div className={S.buttonWrapper}>
                <DA_Button
                    title={getDictionaryString("General.Read")}
                    href={`${relativePath}`}
                    target="_self"
                />
            </div>
        </div>
    );
};
