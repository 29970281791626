import { useCallback, useEffect, useState } from "react";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { Position, Department, PositionCategory } from "../JobListPage.interface";

export const useItems = () => {
    const [departments, setDepartments] = useState<Department[]>(undefined);
    const [categories, setCategories] = useState<PositionCategory[]>(undefined);
    const [allPositions, setAllPositions] = useState<Position[]>(undefined);
    const [cities, setCities] = useState<string[]>([]);
    const [countries, setCountries] = useState<string[]>([]);
    const [viewState, setViewState] = useState<string>("INIT");

    const fetchItems = useCallback(async () => {
        try {
            const url = getAbsoluteUrl("/getpositionlist", "SPA");
            const request = await fetch(url);

            if (request) {
                const response = await request.json();
                return response;
            }
        } catch {
            setViewState("ERROR");
        }
    }, []);

    const getItems = useCallback(async () => {
        setViewState("LOADING");

        try {
            const response = await fetchItems();

            if (response.categories?.[0]) {
                setCategories(response.categories);
            }

            if (response.departments?.[0]) {
                setDepartments(response.departments);
            }

            if (response.cities?.[0]) {
                setCities(response.cities);
            }

            if (response.countries?.[0]) {
                setCountries(response.countries);
            }

            if (response.positions?.[0]) {
                setAllPositions(response.positions);

                setViewState("DONE");
            } else {
                setViewState("EMPTY");
            }
        } catch (error) {
            setCategories(undefined);
            setDepartments(undefined);
            setCities(undefined);
            setCategories(undefined);
            setAllPositions(undefined);
            setViewState("ERROR");
        }
    }, [fetchItems]);

    // Init
    useEffect(() => {
        getItems();

        return () => {
            setCategories(undefined);
            setDepartments(undefined);
            setCities(undefined);
            setCategories(undefined);
            setAllPositions(undefined);
            setViewState("INIT");
        };
    }, [getItems]);

    return {
        departments,
        categories,
        allPositions,
        viewState,
        cities,
        countries,
    };
};
