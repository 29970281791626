// DA_LanguageSelector.tsx
import React from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { getFlagComponent } from "./flagsList";
import { useLanguageSelector } from "./hooks/useLanguageSelector.hook";
import { LanguageSelectorCultureProps } from "./LanguageSelector.interface";
import S from "./LanguageSelector.module.scss";

type DA_LanguageSelectorProps = {
    showLanguageName?: boolean;
    showDropDownArrow?: boolean;
};

export const DA_LanguageSelector = ({
    showLanguageName = false,
    showDropDownArrow = false,
}: DA_LanguageSelectorProps) => {
    const { currentLanguage, isDropdownOpen, setDropdownOpen, handleLanguageChange, dropdownRef } =
        useLanguageSelector();

    const { getDictionaryString } = useTranslations();

    // Sort the cultures list
    const sortedCultures = React.useMemo(() => {
        const otherCultures = globalThis.siteSettingsObj.languageSelectorCultures
            .filter((culture) => culture.culture !== currentLanguage)
            // Sort other cultures alphabetically
            .sort((a, b) => a.culture.localeCompare(b.culture));

        const currentCulture = globalThis.siteSettingsObj.languageSelectorCultures.find(
            (culture) => culture.culture === currentLanguage
        );

        return currentCulture ? [currentCulture, ...otherCultures] : otherCultures;
    }, [currentLanguage]);

    return (
        <button
            ref={dropdownRef}
            className={S.languageSelector}
            onClick={() => setDropdownOpen(!isDropdownOpen)}
        >
            <div className={S.currentLanguage}>
                <span className={S.flagMobile}>
                    {currentLanguage && getFlagComponent(currentLanguage)}
                </span>
                {showLanguageName && (
                    <span className={S.languageName}>
                        {getDictionaryString(`${currentLanguage}-langauge`)}
                    </span>
                )}
            </div>
            {showDropDownArrow &&
                (!isDropdownOpen ? (
                    <DA_Icon name={DA_IconNames.ChevronSmallDown} className={S.more} />
                ) : (
                    <DA_Icon name={DA_IconNames.ChevronSmallUp} className={S.more} />
                ))}

            {isDropdownOpen && (
                <div className={S.dropdown}>
                    {sortedCultures.map((culture: LanguageSelectorCultureProps) => (
                        <div
                            key={culture.culture}
                            onClick={() => handleLanguageChange(culture.culture)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ")
                                    handleLanguageChange(culture.culture);
                            }}
                            className={`${S.option} ${
                                currentLanguage === culture.culture ? S.active : ""
                            }`}
                            tabIndex={0}
                            role="button"
                        >
                            {getFlagComponent(culture.culture)}

                            <span className={S.languageName}>
                                {getDictionaryString(`${culture.culture}-langauge`)}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </button>
    );
};
