import React from "react";
import reactStringReplace from "react-string-replace";
import { DA_Product } from "../../../../../interfaces/product.interface";
import { DA_BasicLink } from "../../../../atoms/BasicLink/BasicLink.component";
import { getB2bImage } from "../../../../../helpers/getB2bImage.helper";
import { B2B_SITE_ORIGIN } from "../../../../../constants/urlRoot.constants";
import { getRelativeRootOnLocalhost } from "../../../../../helpers/getRelativeUrlOnLocalhost.helper";
import S from "./SearchProduct.module.scss";

export const _SearchProduct = ({
    highlightedString,
    onResultClicked,
    ...props
}: DA_Product & { highlightedString: string; onResultClicked: () => void }) => {
    const image = getB2bImage(props.primaryImage, 50);

    return (
        <DA_BasicLink
            onClick={onResultClicked}
            href={`${getRelativeRootOnLocalhost(B2B_SITE_ORIGIN)}/${props.url}`}
            target="_self"
            className={S.link}
        >
            <figure className={S.image}>
                <img src={image.url} alt={image.alt} width={image.width} height={image.height} />
            </figure>
            <div className={S.data}>
                <div className={S.details}>
                    <div className={S.productNumber}>{props.productNumber}</div>
                    {reactStringReplace(
                        props.name,
                        new RegExp(`(${highlightedString})`, "ig"),
                        (match, index) => (
                            <b key={index}>{match}</b>
                        )
                    )}
                </div>
            </div>
        </DA_BasicLink>
    );
};
