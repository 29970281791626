import React, { useMemo } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import {
    DA_NavigationPage,
    DA_NavigationPageSimple,
} from "../../../../../interfaces/navigationPage.interface";
import { DA_BasicLink } from "../../../../atoms/BasicLink/BasicLink.component";
import { useAppData } from "../../../../../contexts/appData.context";
import { useTranslations } from "../../../../../contexts/translations/translations.context";
import S from "./ColumnList.module.scss";

interface Props {
    parentLink: string;
    pages: (DA_NavigationPage | DA_NavigationPageSimple)[];
    currentItems: DA_NavigationPage[];
    setOpenMenuItem: (index: number) => void;
}

export const _ColumnList = ({ parentLink, pages, currentItems, setOpenMenuItem }: Props) => {
    const { currentSite } = useAppData();
    const { getDictionaryString } = useTranslations();

    const filteredPages = useMemo(() => pages.filter((link) => !link.hideInNavigation), [pages]);
    const enablePageLimit = filteredPages.length > 5 && currentSite !== "CMS";

    return (
        <ul className={S.columnList}>
            {filteredPages.slice(0, enablePageLimit ? 4 : undefined).map((link, linkIndex) => {
                const currentItem = currentItems?.[2];
                const isCurrentClass =
                    currentItem && link.path === currentItem?.path ? S.active : undefined;

                return (
                    <li key={linkIndex} className={S.columnItem}>
                        <DA_BasicLink
                            href={link.redirectUrl || link.url || link.path}
                            className={cn(S.columnLink, isCurrentClass)}
                            onClick={() => setOpenMenuItem(-1)}
                        >
                            {link.name}
                        </DA_BasicLink>
                    </li>
                );
            })}

            {/** Show all filter */}
            {enablePageLimit && (
                <li className={S.columnItem}>
                    <DA_BasicLink
                        href={parentLink}
                        className={S.showAllLink}
                        onClick={() => setOpenMenuItem(-1)}
                    >
                        {getDictionaryString("showAll", {
                            uppercaseFirst: true,
                        })}
                    </DA_BasicLink>
                </li>
            )}
        </ul>
    );
};
