import React, { useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { getLoginLink } from "@danishagro/shared/src/helpers/getLoginLink.helper";
import { useTranslations } from "../../../../contexts/translations/translations.context";
import { DA_Button } from "../../../atoms/Button/Button.component";
import { DA_Text } from "../../../atoms/Text/Text.component";
import { DA_Container } from "../../../atoms/Container/Container.component";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { useSiteHeader } from "../../../../contexts/siteHeader.context";
import { useAppData } from "../../../../contexts/appData.context";
import { Menu } from "./components/Menu/Menu.component";
import { Tabs } from "./components/Tabs/Tabs.component";
import { DA_NavigationMobileProps } from "./NavigationMobile.props";
import { useWebshop } from "./hooks/useWebshop.hook";
import { useMyFarm } from "./hooks/useMyfarm.hook";
import { useCms } from "./hooks/useCms.hook";
import S from "./NavigationMobile.module.scss";

export const DA_NavigationMobile = (props: DA_NavigationMobileProps) => {
    const { currentSite } = useAppData();
    const { getDictionaryString } = useTranslations();
    const { toggleMobileNav, pageTree } = useSiteHeader();

    const { webshopNavigation } = useWebshop(pageTree);
    const { myfarmNavigation } = useMyFarm(pageTree);
    const { cmsNavigation } = useCms(pageTree);

    const navigationRef = useRef<HTMLDivElement>();

    const currentNavigationData = useMemo(() => {
        if (currentSite === "B2B") {
            return webshopNavigation;
        } else if (currentSite === "MYFARM") {
            return myfarmNavigation;
        } else if (currentSite === "CMS") {
            return cmsNavigation;
        }
    }, [currentSite, webshopNavigation, myfarmNavigation, cmsNavigation]);

    const isOpenClass = props.isOpen && S.isOpen;

    return ReactDOM.createPortal(
        <div className={cn(S.wrapper, isOpenClass)}>
            <div className={S.inner} ref={navigationRef}>
                {globalThis.apiGatewayUrl ? <Tabs /> : null}
                {currentSite === "B2B" ||
                currentSite === "CMS" ||
                (globalThis.isAuthenticated && currentSite === "MYFARM") ? (
                    <Menu
                        menuItems={currentNavigationData}
                        customerServiceLinks={props.customerServiceLinks}
                        navigationRef={navigationRef}
                    />
                ) : null}
                {!globalThis.isAuthenticated && currentSite === "MYFARM" ? (
                    <DA_Container className={S.container}>
                        <div>
                            <DA_Text noPadding>
                                {getDictionaryString("log in to see myfarm")}
                            </DA_Text>

                            <DA_Button
                                className={S.button}
                                onClick={toggleMobileNav}
                                href={getLoginLink(currentSite)}
                                title={getDictionaryString("logIn")}
                            />
                        </div>

                        {/** Customer Service */}
                        <div className={S.customerServiceLinks}>
                            {props?.customerServiceLinks?.map((item, index) => (
                                <div key={index}>
                                    <DA_BasicLink
                                        target="_self"
                                        href={item.href}
                                        onClick={toggleMobileNav}
                                        className={S.customerServiceLink}
                                    >
                                        {item.title}
                                    </DA_BasicLink>
                                </div>
                            ))}
                        </div>
                    </DA_Container>
                ) : null}
            </div>
        </div>,
        document.body
    );
};
