/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, Suspense } from "react";
import { Block } from "@interfaces/page.interface";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useBlockDataMapping } from "./hooks/useBlockDataMapping.hook";
import useRecursiveBlock from "./hooks/useRecursiveBlock.hook";

export const DynamicBlock = (props: Block) => {
    const { siteVariant } = useAppData();

    const getTemplate = useRecursiveBlock(siteVariant.alias);

    const AdvancedTable = getTemplate("AdvancedTable");
    const ContentList = getTemplate("ContentList");
    const DepartmentMap = getTemplate("DepartmentMap");
    const Embed = getTemplate("Embed");
    const Employees = getTemplate("Employees");
    const Hero = getTemplate("Hero");
    const Image = getTemplate("Image");
    const NewsSlider = getTemplate("NewsSlider");
    const Quotes = getTemplate("Quotes");
    const Spots = getTemplate("Spots");
    const Tabs = getTemplate("Tabs");
    const Text = getTemplate("Text");
    const TextAccordions = getTemplate("TextAccordions");
    const TextForm = getTemplate("TextForm");
    const TextImage = getTemplate("TextImage");
    const TextImageBanner = getTemplate("TextImageBanner");
    const TextTable = getTemplate("TextTable");
    const TextText = getTemplate("TextText");
    const TextVideo = getTemplate("TextVideo");
    const Video = getTemplate("Video");
    const TextGraphics = getTemplate("TextGraphics");

    const BLOCK_TYPES: { [key: string]: any } = {
        blockAdvancedTable: AdvancedTable,
        blockContentList: ContentList,
        blockDepartmentMap: DepartmentMap,
        blockEmbed: Embed,
        blockEmployees: Employees,
        blockHero: Hero,
        blockImage: Image,
        blockNewsSlider: NewsSlider,
        blockQuote: Quotes,
        blockSpots: Spots,
        blockTabs: Tabs,
        blockText: Text,
        blockTextAccordions: TextAccordions,
        blockTextForm: TextForm,
        blockTextImage: TextImage,
        blockTextImageBanner: TextImageBanner,
        blockTextTable: TextTable,
        blockTextText: TextText,
        blockTextVideo: TextVideo,
        blockVideo: Video,
        blockTextGraphics: TextGraphics,
    };

    const contentType = props.content.alias || "blockNotFound";
    const Block = BLOCK_TYPES[contentType];

    // Mappers

    const mappedData = useBlockDataMapping(contentType, props);

    return (
        <Fragment>
            {Block ? (
                <Suspense fallback={null}>
                    <Block {...mappedData} isCompact={props.compact} />
                </Suspense>
            ) : null}
        </Fragment>
    );
};
