import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { DA_CartData } from "@danishagro/shared/src/interfaces/cartData.interface";
import { DA_CartOrderLine } from "@danishagro/shared/src/interfaces/cartOrderLine.interface";
import { FetchOptions } from "@danishagro/shared/src/interfaces/fetchOptions.interface";
import { DA_CartPriceData } from "@danishagro/shared/src/interfaces/price.interface";
import { usePrevious } from "@danishagro/shared/src/hooks/usePrevious.hook";
import { useCustomer } from "@danishagro/shared/src/hooks/useCustomer.hook";
import { DA_Toast } from "@danishagro/shared/src/components/molecules/Toast/Toast.component";
import { B2B_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";
import { useTranslations } from "../translations/translations.context";
import { getRelativeRootOnLocalhost } from "../../helpers/getRelativeUrlOnLocalhost.helper";
import { useRequest } from "../../hooks/useRequest.hook";
import { ExpressDeliveryModes } from "../../interfaces/expressDeliveryModes.interface";
import { useCartRequest } from "./hooks/useCartRequest.hook";
import { useCartPrice } from "./hooks/useCartPrice.hook";

export const CROP_PROTECTION_DELIVERY_MODE_DEFAULT = "11";

interface CartArgs {
    quantity: number;
    productId: string;
    productVariantId?: string;
}

interface ContactArgs {
    companyName: string;
    cvr: string;
    email: string;
    phone: string;
}

interface DeliveryArgs {
    billingAddress?: {
        streetName: string;
        streetNumber: string;
        zipCode: string;
        city: string;
    };
    shippingAddress: {
        streetName: string;
        streetNumber: string;
        zipCode: string;
        city: string;
    };
    shippingSameAsBilling: boolean;
}

interface CartHook {
    cartId?: string;
    orderSecret?: string;
    priceData: DA_CartPriceData | undefined;
    priceStatus: ReturnType<typeof useCartPrice>["priceStatus"];
    customerDetails: DA_CartData["customerDetails"];
    shippingDetails: DA_CartData["shippingDetails"];
    latestShippingDate?: Date;
    orderLines: DA_CartOrderLine[];
    addToCart: (properties: CartArgs, options?: FetchOptions) => Promise<DA_CartData>;
    updateCart: (properties: CartArgs, options?: FetchOptions) => Promise<DA_CartData>;
    removeFromCart: (properties: CartArgs, options?: FetchOptions) => Promise<DA_CartData>;
    resetCart: () => void;
    saveContactInfo: (properties: ContactArgs, options?: FetchOptions) => Promise<DA_CartData>;
    updateExpressDeliveryModes: (
        properties: ExpressDeliveryModes,
        options?: FetchOptions
    ) => Promise<DA_CartData>;
    expressDeliveryModes: ExpressDeliveryModes;
    saveDeliveryInfo: (properties: DeliveryArgs, options?: FetchOptions) => Promise<DA_CartData>;
    completeOrder: (options?: FetchOptions) => Promise<string>;
    getCompletedOrder: (
        orderSecret: string,
        options?: FetchOptions
    ) => Promise<DA_CartData & { price: DA_CartPriceData; completedDate: string }>;
    isLoadingCart: boolean;
    isUpdatingCart: boolean;
    isFarmInTimeCart: boolean;
}

const CartContext = React.createContext<CartHook>({} as CartHook);

type Props = {
    customerNumber?: string;
    orderSecret?: string;
    children: React.ReactNode;
};

export const CartProvider = ({
    customerNumber,
    orderSecret: givenOrderSecret,
    children,
}: Props): JSX.Element => {
    const [cartId, setCartId] = useState<string>();
    const [orderSecret, setOrderSecret] = useState<string>(givenOrderSecret);
    const [isLoadingCart, setIsLoadingCart] = useState(false);
    const [isUpdatingCart, setIsUpdatingCart] = useState(false);
    const [customerDetails, setCustomerDetails] = useState<DA_CartData["customerDetails"]>();
    const [shippingDetails, setShippingDetails] = useState<DA_CartData["shippingDetails"]>();
    const [expressDeliveryModes, setExpressDeliveryModes] = useState<ExpressDeliveryModes>({
        isExpressDelivery: false,
        cropProtectionDeliveryMode: CROP_PROTECTION_DELIVERY_MODE_DEFAULT,
    });
    const [orderLines, setOrderLines] = useState<DA_CartOrderLine[]>([]);
    const { getDictionaryString } = useTranslations();
    const { customer } = useCustomer();
    const previousCustomerNumber = usePrevious(customerNumber);

    const request = useRequest();
    const b2bRequest = useCartRequest();
    const { priceData, priceStatus, latestShippingDate } = useCartPrice(
        customerNumber,
        orderSecret,
        orderLines,
        expressDeliveryModes,
        isUpdatingCart
    );

    const getStoredSecret = useCallback(() => {
        const secret = localStorage.getItem(`orderSecret:${customerNumber}`);
        if (secret) {
            setOrderSecret(secret);
            return secret;
        }
        return null;
    }, [customerNumber]);

    const storeSecret = useCallback(
        (secret: string) => {
            if (orderSecret !== secret) {
                localStorage.setItem(`orderSecret:${customerNumber}`, secret);
                setOrderSecret(secret);
            }
        },
        [orderSecret, customerNumber]
    );

    const resetSecret = useCallback(() => {
        localStorage.removeItem(`orderSecret:${customerNumber}`);
        setOrderSecret(undefined);
    }, [customerNumber]);

    const updateCartData = useCallback(
        (cartData: Omit<DA_CartData, "price">) => {
            if (cartData) {
                setCartId(cartData.id);
                setCustomerDetails(cartData.customerDetails);
                setOrderLines(
                    // TODO: Remove this map-function when FO can handle express delivery
                    cartData.orderLines.map((line) => ({ ...line, allowExpressDelivery: false }))
                );
                setShippingDetails(cartData.shippingDetails);
                setExpressDeliveryModes({
                    isExpressDelivery: cartData.isExpressDelivery,
                    cropProtectionDeliveryMode: cartData.cropProtectionDeliveryMode,
                });
                storeSecret(cartData.secret);
            }
        },
        [storeSecret]
    );

    const resetCart = useCallback(() => {
        setCartId(undefined);
        setCustomerDetails(undefined);
        setOrderLines([]);
        setShippingDetails(undefined);
        resetSecret();
    }, [resetSecret]);

    const getCart = useCallback(
        (options?: FetchOptions) => {
            setIsLoadingCart(true);

            const properties = {};
            // Farm in time cart context
            if (customerNumber && givenOrderSecret) {
                properties["customerNumber"] = customerNumber;
                properties["secret"] = orderSecret;
            }
            // Logged in get latest cart
            else if (customerNumber) {
                properties["customerNumber"] = customerNumber;
            }
            // Receipt
            else {
                properties["secret"] = orderSecret;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .get("/Cart/GetCart", properties, options)
                    .then((data) => {
                        if (data.success) {
                            updateCartData(data.cart);
                            resolve(data.cart);
                        } else {
                            reject(data.message);
                        }
                        setIsLoadingCart(false);
                    })
                    .catch(() => setIsLoadingCart(false))
            );
        },
        [customerNumber, givenOrderSecret, orderSecret, b2bRequest, updateCartData]
    );

    const addToCart: CartHook["addToCart"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                productId: properties.productId,
                variantId: properties.productVariantId,
                quantity: properties.quantity,
                orderSecret,
            };
            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .post("/Cart/AddToCart", props, options)
                    .then((data) => {
                        if (data.success) {
                            updateCartData(data.cart);
                            resolve(data.cart);
                            toast.custom(() => (
                                <DA_Toast
                                    href={`${getRelativeRootOnLocalhost(B2B_SITE_ORIGIN)}/cart`}
                                    goTo={getDictionaryString("ToastShowCart")}
                                    message={
                                        <>
                                            <div>{getDictionaryString("ToastAddedToCartText")}</div>
                                            {customer && (
                                                <div className="break-all">
                                                    {getDictionaryString("ToastAccountText")}
                                                    <strong>{customer?.name}</strong>
                                                </div>
                                            )}
                                        </>
                                    }
                                />
                            ));
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [orderSecret, customerNumber, b2bRequest, updateCartData, getDictionaryString, customer]
    );

    const updateCart: CartHook["updateCart"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                productId: properties.productId,
                variantId: properties.productVariantId,
                quantity: properties.quantity,
                orderSecret,
            };
            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .patch("/Cart/UpdateCart", props, options)
                    .then((data) => {
                        if (data.success) {
                            updateCartData(data.cart);
                            resolve(data.cart);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, customerNumber, orderSecret, updateCartData]
    );

    const removeFromCart: CartHook["removeFromCart"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                productId: properties.productId,
                variantId: properties.productVariantId,
                quantity: 0,
                orderSecret,
            };
            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .delete("/Cart/RemoveFromCart", props, options)
                    .then((data) => {
                        if (data.success) {
                            updateCartData(data.cart);
                            resolve(data.cart);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, customerNumber, orderSecret, updateCartData]
    );

    const saveContactInfo: CartHook["saveContactInfo"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                ...properties,
                orderSecret,
            };

            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .post("/checkout/SaveContactInfo", props, options)
                    .then((data) => {
                        if (data.success) {
                            updateCartData(data.cart);
                            resolve(data.cart);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, customerNumber, orderSecret, updateCartData]
    );

    const updateExpressDeliveryModes: CartHook["updateExpressDeliveryModes"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                ...properties,
                orderSecret,
            };

            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .post("/Cart/UpdateCartExpressDeliveryModes", props, options)
                    .then((data) => {
                        if (data.success) {
                            updateCartData(data.cart);
                            resolve(data.cart);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, customerNumber, orderSecret, updateCartData]
    );

    const saveDeliveryInfo: CartHook["saveDeliveryInfo"] = useCallback(
        (properties, options) => {
            setIsUpdatingCart(true);

            const props = {
                ...properties,
                orderSecret,
            };

            if (customerNumber) {
                props["customerNumber"] = customerNumber;
            }

            return new Promise((resolve, reject) =>
                b2bRequest
                    .post("/checkout/SaveDeliveryInfo", props, options)
                    .then((data) => {
                        if (data.success) {
                            updateCartData(data.cart);
                            resolve(data.cart);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
                    .finally(() => setIsUpdatingCart(false))
            );
        },
        [b2bRequest, customerNumber, orderSecret, updateCartData]
    );

    const completeOrder: CartHook["completeOrder"] = useCallback(
        (options) => {
            const props = {
                orderSecret,
            };

            return new Promise((resolve, reject) =>
                request
                    .post(`/orders/${customerNumber}/complete`, props, options)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            resolve(orderSecret);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
            );
        },
        [request, customerNumber, orderSecret]
    );

    const getCompletedOrder: CartHook["getCompletedOrder"] = useCallback(
        (secret, options) =>
            new Promise((resolve, reject) =>
                b2bRequest
                    .get("/Checkout/GetCompletedOrder", { secret, customerNumber }, options)
                    .then((data) => {
                        if (data.success) {
                            resolve(data.cart);
                        } else {
                            reject(data.message);
                        }
                    })
                    .catch((error) => reject(error))
            ),
        [b2bRequest, customerNumber]
    );

    // Get secret on page load
    useEffect(() => {
        if (!cartId && !orderSecret && !givenOrderSecret) {
            getStoredSecret();
        }
    }, [cartId, orderSecret, givenOrderSecret, getStoredSecret]);

    // Get cart on page load (using secret or customer number)
    useEffect(() => {
        if (!cartId && (orderSecret || customerNumber)) {
            getCart().catch(() => {
                if (orderSecret) {
                    // If cart doesn't exist, reset the stored secret
                    resetSecret();
                }
            });
        }
    }, [cartId, orderSecret, customerNumber, getCart, resetSecret]);

    // Reset cart when customer number is changed
    useEffect(() => {
        if (customerNumber && previousCustomerNumber && customerNumber !== previousCustomerNumber) {
            resetCart();
        }
    }, [resetCart, customerNumber, previousCustomerNumber]);

    return (
        <CartContext.Provider
            value={{
                cartId,
                orderSecret,
                priceData,
                priceStatus,
                orderLines,
                customerDetails,
                shippingDetails,
                latestShippingDate,
                addToCart,
                updateCart,
                removeFromCart,
                resetCart,
                saveContactInfo,
                updateExpressDeliveryModes,
                expressDeliveryModes,
                saveDeliveryInfo,
                completeOrder,
                getCompletedOrder,
                isLoadingCart,
                isUpdatingCart,
                isFarmInTimeCart: typeof givenOrderSecret !== "undefined",
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = (): CartHook => React.useContext(CartContext);
