import {
    GET_B2B_SITE_ORIGIN,
    GET_CMS_SITE_ORIGIN,
    GET_MYFARM_SITE_ORIGIN,
} from "@danishagro/shared/src/helpers/urlRoot.helper";

/** SITE ORIGINS  */

export const MYFARM_SITE_ORIGIN = GET_MYFARM_SITE_ORIGIN();
export const B2B_SITE_ORIGIN = GET_B2B_SITE_ORIGIN();
export const CMS_SITE_ORIGIN = GET_CMS_SITE_ORIGIN();

/** API ROOTS */

export const API_GATEWAY_ROOT = (
    globalThis.apiGatewayUrl
        ? globalThis.apiGatewayUrl
        : location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? "https://myfarm-api-dev.danishagro.com/api"
        : ""
).replace(/\/$/g, "");

export const B2B_API_ROOT = (
    globalThis.b2bApiUrl
        ? globalThis.b2bApiUrl
        : location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? "https://app-b2bshop-api-dev.azurewebsites.net"
        : ""
).replace(/\/$/g, "");

export const CMS_API_ROOT = (
    globalThis.cmsApiUrl
        ? globalThis.cmsApiUrl
        : location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? "https://danish-agro-basesite.euwest01.umbraco.io"
        : ""
).replace(/\/$/g, "");

export const CMS_CDN_ROOT = (globalThis.cmsCdnUrl ? globalThis.cmsCdnUrl : "").replace(/\/$/g, "");
