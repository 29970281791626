import React, { useLayoutEffect, useRef, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { setImageOptions } from "./helpers/setImageOptions.helper";
import { ImageViewerProps } from "./ImageViewer.interface";
import S from "./ImageViewer.module.scss";

export const ImageViewer = (props: ImageViewerProps) => {
    const elementRef = useRef<HTMLDivElement>(null);

    const [imageUrlWithOptions, setImageUrlWithOptions] = useState<string>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const aspectRatioStyle =
        props.aspectRatioX && props.aspectRatioY
            ? {
                  aspectRatio: `${props.aspectRatioX}/${props.aspectRatioY}`,
              }
            : undefined;

    const onLoadedClass = isLoaded ? props.loadedClassName : undefined;

    useLayoutEffect(() => {
        if (elementRef.current) {
            // Based on the given aspect-ratio, the div is sized to those proportions
            // We then need to figure out how many pixels that is, to generate an image to fit that.
            setTimeout(() => {
                const boundingClientRect = elementRef.current?.getBoundingClientRect();
                const boundingClientWidth = boundingClientRect?.width;
                const boundingClientHeight = elementRef.current?.clientHeight;

                const clientWidth =
                    boundingClientWidth && boundingClientWidth > 0
                        ? boundingClientWidth * (window?.devicePixelRatio || 1)
                        : undefined;
                const clientHeight =
                    boundingClientHeight && boundingClientHeight > 0
                        ? boundingClientHeight * (window?.devicePixelRatio || 1)
                        : undefined;

                // TODO: Set a contain or cover setting on the image processor.

                let digizuiteSrc = "";

                if (props.src?.includes("assetID")) {
                    digizuiteSrc = props.src
                        .replace("{QUALITY}", "90")
                        .replace("{WIDTH}", `${clientWidth}`)
                        .replace("{HEIGHT}", `${clientHeight}`);

                    setImageUrlWithOptions(digizuiteSrc);
                } else {
                    const imageUrlWithOptions = setImageOptions(
                        props.src,
                        clientWidth,
                        clientHeight,
                        props.aspectRatioX,
                        props.aspectRatioY
                    );
                    setImageUrlWithOptions(imageUrlWithOptions);
                }
            }, 100);
        }
    }, [props.aspectRatioX, props.aspectRatioY, props.src]);

    return (
        <div
            className={cn(S.wrapper, onLoadedClass, props.className)}
            ref={elementRef}
            style={aspectRatioStyle}
        >
            <img
                className={S.image}
                src={imageUrlWithOptions}
                alt={props.alternative}
                loading={props.lazy === false ? "eager" : "lazy"}
                onLoad={() => setIsLoaded(true)}
            />
        </div>
    );
};
