/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Vimeo from "@u-wave/react-vimeo";
import YouTube from "@u-wave/react-youtube";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl.helper";
import { getYouTubeId, getVimeoId } from "@baggie/core";
import { ImageViewer } from "@danishagro/cms/src/components/ImageViewer/ImageViewer.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useMarketingConsent } from "./hooks/useMarketingConsent.hook";
import { VideoPlayerViewerProps } from "./VideoPlayerViewer.interface";
import S from "./VideoPlayerViewer.module.scss";

const VideoPlayerViewer = (props: VideoPlayerViewerProps) => {
    const { getDictionaryString } = useTranslations();
    const { marketingConsent } = useMarketingConsent();

    //Autoplay with intro
    const [autoplayWithIntro, setAutoplayWithIntro] = useState(
        props.video?.autoplay ? true : false
    );

    const [videoInnerRef, videoInnerDimensions] = useMeasure();
    const [consentWrapperRef] = useMeasure();

    const [viewState, setViewState] = useState<string>(props.posterStart ? "INTRO" : "VIDEO");

    const autoplay = props.video?.autoplay ? true : false;
    const loop = props.video?.autoplay ? true : false;
    const muted = props.video?.autoplay ? true : false;
    const hasControl = props.video.autoplay ? false : true;
    const hasControlOption = props.video.controls ?? true;

    const [isSmall, setIsSmall] = useState(false);

    function useMeasure(): [MutableRefObject<any>, { width: number; height: number }] {
        const ref = useRef<any>(null);
        const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

        useEffect(() => {
            if (ref.current) {
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        }, []); // Empty dependency array

        return [ref, dimensions];
    }

    useEffect(() => {
        const updateSize = () => {
            const videoInnerHeight = videoInnerDimensions.height;

            // If the height is less than 300, set isSmall to true
            if (videoInnerHeight < 290 && !isSmall) {
                setIsSmall(true);
            }
            // If the height is greater or equal to 300, set isSmall to false
            else if (videoInnerHeight >= 290 && isSmall) {
                setIsSmall(false);
            }
        };

        // Start the update
        updateSize();
    }, [videoInnerDimensions, isSmall]);

    const onVideoEnded = () => {
        if (props.posterEnd) {
            setViewState("OUTRO");
        }
    };

    const onVideoReady = (event: any) => {
        if (props.video?.autoplay && props.video.alias === "youtube") {
            // Bugfix to autoplaying with YouTube
            setTimeout(() => {
                event?.target?.playVideo();
            }, 10);
        }
    };

    const onPosterClicked = () => {
        setViewState("VIDEO");
        // Autoplay video
        setAutoplayWithIntro(true);
    };

    const handleRenew = () => {
        if (window.CookieConsent && typeof window.CookieConsent.renew === "function") {
            window.CookieConsent.renew();
        }
    };

    return (
        <>
            {/** Intro */}
            {viewState === "INTRO" ? (
                <div className={S.introWrapper}>
                    <div className={cn(S.videoInner, props.className)} onClick={onPosterClicked}>
                        <ImageViewer
                            src={props.posterStart?.url}
                            aspectRatioX={16}
                            aspectRatioY={9}
                            className={S.image}
                        />
                    </div>

                    <div className={S.playIconContainer}>
                        <DA_Icon name={DA_IconNames.Play} className={S.iconSize} />
                    </div>
                </div>
            ) : null}

            {/** Video: YouTube */}
            {viewState === "VIDEO" && props.video?.alias === "youtube" && props.video.url ? (
                <div className={cn(S.videoInner, props.className)}>
                    <YouTube
                        video={getYouTubeId(props.video.url)}
                        modestBranding={true}
                        onEnd={onVideoEnded}
                        autoplay={autoplayWithIntro}
                        muted={muted}
                        controls={hasControl}
                        className={S.video}
                        onReady={onVideoReady}
                    />
                </div>
            ) : null}

            {/** Video: Vimeo */}
            {viewState === "VIDEO" && props.video?.alias === "vimeo" && props.video.url ? (
                <div className={cn(S.videoInner, props.className)}>
                    <Vimeo
                        video={getVimeoId(props.video.url)}
                        responsive={true}
                        onEnd={onVideoEnded}
                        autoplay={autoplayWithIntro}
                        controls={hasControl}
                        className={S.video}
                        muted={muted}
                        loop={loop}
                        onReady={onVideoReady}
                    />
                </div>
            ) : null}

            {/** Video: Local */}
            {viewState === "VIDEO" && props.video?.alias === "local" && props.video.url ? (
                <div className={cn(S.videoInner, props.className)}>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                        src={getAbsoluteUrl(props.video.url, "UMBRACO")}
                        controls={hasControlOption}
                        muted={muted}
                        autoPlay={autoplay}
                        loop={loop}
                        onCanPlay={onVideoReady}
                        onEnded={onVideoEnded}
                        className={S.video}
                        playsInline // playsinline for working in Safari / iphone
                    />
                </div>
            ) : null}

            {/** Outro */}
            {viewState === "OUTRO" ? (
                <>
                    <div className={cn(S.videoInner, props.className)} onClick={onPosterClicked}>
                        <ImageViewer
                            src={props.posterEnd.url}
                            aspectRatioX={16}
                            aspectRatioY={9}
                            className={S.image}
                        />
                    </div>

                    <div className={S.playIconContainer}>
                        <DA_Icon name={DA_IconNames.Play} className={S.iconSize} />
                    </div>
                </>
            ) : null}

            {/** Caption */}
            {props.video.caption ? <div className={S.caption}>{props.video.caption}</div> : null}

            {/* No cookie consent video placeholder */}
            {(props.video?.alias === "youtube" || props.video?.alias === "vimeo") &&
                location.hostname !== "localhost" &&
                window.CookieConsent &&
                !marketingConsent && (
                    <div
                        ref={videoInnerRef}
                        className={S.consentPlaceholderWrapper}
                        data-category="cookie_cat_marketing"
                    >
                        <div
                            ref={consentWrapperRef}
                            className={cn(S.consentPlaceholderBox, isSmall && S.smallContent)}
                        >
                            <DA_Icon name={DA_IconNames.Cookie} className={S.iconSize} />
                            <DA_Title h2 tag={DA_TitleTag.H2}>
                                {getDictionaryString("ConsentVideoPlaceholder.Headline")}
                            </DA_Title>
                            <div className={S.consentPlaceholderText}>
                                <DA_Text
                                    html={getDictionaryString("ConsentVideoPlaceholder.Text")}
                                />
                            </div>

                            <DA_Button
                                title={getDictionaryString("ConsentVideoPlaceholder.Button")}
                                onClick={handleRenew}
                            />
                        </div>
                    </div>
                )}
        </>
    );
};

export default VideoPlayerViewer;
