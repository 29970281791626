import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getAbsoluteUrl } from "./getAbsolutePath.hook";

export const useLanguageSelector = () => {
    const [currentLanguage, setCurrentLanguage] = useState<string | null>(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    // Constants
    const COOKIE_EXPIRE_DAYS = 90;
    const { languageSelectorCultures } = globalThis.siteSettingsObj;
    const allowedPaths = languageSelectorCultures.map((culture) => culture.path);

    // Utility function to navigate based on culture
    const navigateToPathBasedOnCulture = (cultureAlias: string) => {
        const selectedCulture = languageSelectorCultures.find(
            (culture) => culture.culture === cultureAlias
        );
        if (selectedCulture) navigate(selectedCulture.path);
    };

    // Fetch items based on location and set cookies accordingly
    const fetchItems = useCallback(async () => {
        const cultureCode = globalThis?.cultures[0]?.alias;

        try {
            const url = getAbsoluteUrl(`GetLocationFromIp`, "SPA", {
                rootNodeId: globalThis.rootNodeId,
            });
            const request = await fetch(`${url}&cultureCode=${cultureCode}`);

            const location = await request.text();

            const matchedCulture = languageSelectorCultures.find((culture) =>
                culture.culture.includes(location)
            )?.culture;

            if (matchedCulture && !Cookies.get("language")) {
                setCurrentLanguage(matchedCulture);
                Cookies.set("language", matchedCulture, { expires: COOKIE_EXPIRE_DAYS });
            }
        } catch (error) {
            console.error("Error in fetchItems:", error.message);
        }

        const cookieLanguage = Cookies.get("language");

        // Set language based on cookies or default to active culture
        if (cookieLanguage && allowedPaths.includes(window.location.pathname)) {
            setCurrentLanguage(cookieLanguage);
            navigateToPathBasedOnCulture(cookieLanguage);
        } else {
            const activeCulture = globalThis.cultures.find((culture) => culture.active)?.alias;
            setCurrentLanguage(activeCulture);
            Cookies.set("language", activeCulture || "defaultLanguage", {
                expires: COOKIE_EXPIRE_DAYS,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageSelectorCultures]);

    // Effect to handle fetching, setting cookie, and other lifecycle tasks
    useEffect(() => {
        const cookieLanguage = Cookies.get("language");

        if (!cookieLanguage) fetchItems();
        else if (allowedPaths.includes(window.location.pathname)) {
            setCurrentLanguage(cookieLanguage);
            navigateToPathBasedOnCulture(cookieLanguage);
        } else {
            const activeCulture = globalThis.cultures.find((culture) => culture.active)?.alias;
            setCurrentLanguage(activeCulture);
            Cookies.set("language", activeCulture || "defaultLanguage", {
                expires: COOKIE_EXPIRE_DAYS,
            });
        }

        // Event handler for outside clicks to close the dropdown
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchItems]);

    // Handle language change from dropdown
    const handleLanguageChange = (selectedCultureAlias: string) => {
        const selectedCulture = languageSelectorCultures.find(
            (culture) => culture.culture === selectedCultureAlias
        );
        if (selectedCulture) {
            navigate(selectedCulture.path);
            setCurrentLanguage(selectedCultureAlias);
            Cookies.set("language", selectedCultureAlias, { expires: COOKIE_EXPIRE_DAYS });
        }
        setDropdownOpen(false);
    };

    return {
        dropdownRef,
        currentLanguage,
        isDropdownOpen,
        setDropdownOpen,
        handleLanguageChange,
    };
};
