import React, { useMemo } from "react";
import { isProduction } from "@helpers/isProduction.helper";
import { Helmet } from "react-helmet";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import Hero from "src/blocks/components/Hero/Hero.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_PageNotFound } from "@danishagro/shared/src/components/organisms/PageNotFound/PageNotFound.component";
import SocialMediaSharing from "src/blocks/components/SocialMediaSharing/SocialMediaSharing.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { usePage } from "@contexts/page.context";
import { DynamicBlock } from "../../../../blocks/DynamicBlock.component";
import { useJobDetail } from "./hooks/useJobDetail.hook";
import { ContactInfo } from "./ContactInfo/ContactInfo.component";
// Import types
import { JobsPageProps } from "./JobsPage.interface";
// Import style
import S from "./JobsPage.module.scss";

export const JobsPage = (props: JobsPageProps) => {
    const { currentPathDetails } = usePage();

    const { getDictionaryString } = useTranslations();
    const { data, viewState } = useJobDetail();

    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props?.props?.allowIndexing === true ? "index" : "noindex"},
                ${props?.props?.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props?.props?.allowFollowing, props?.props?.allowIndexing]);

    const {
        advertisements = [],
        positionCategory = {},
        name = "",
        department = "",
        advertisementUrl = "",
        applicationFormUrl = "",
        startDate = "",
        startDateASAP = false,
        applicationDue = "",
        created = "",
        users = [],
        workPlaceCoordinates = {},
        city = "",
        country = "",
        address = "",
        zip = "",
    } = data ?? {};

    const isDepartmentValid = department && "name" in department;
    const areUsersValid = users && "projectParticipants" in users;
    const areWorkPlaceCoordinatesValid = typeof workPlaceCoordinates === "string";

    //date format - just show the date without time
    //split the string into date and time
    const formattedStartDateSplitParts = startDate.split(" ");
    const formattedDatePart = formattedStartDateSplitParts[0];

    return (
        <main className={S.wrapper} id="main">
            <Helmet>
                <title>{props?.props?.metaTitle}</title>
                <meta name="description" content={props?.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
            </Helmet>

            {/* Breadcrumbs */}
            <DA_Container noVerticalPadding>
                <DA_Breadcrumb
                    breadcrumbItems={[
                        ...currentPathDetails.map(({ name, path }) => ({
                            title: name,
                            href: path,
                        })),
                    ]}
                    lastIsLink={true}
                />
            </DA_Container>

            {/** ViewState: DONE */}
            {viewState === "DONE" && data ? (
                <>
                    {positionCategory && advertisements?.[0]?.imageUrl && (
                        <Hero
                            tagline={getDictionaryString("Job.AvailablePosition")}
                            headline={name}
                            text=""
                            buttons={[
                                {
                                    alias: "buttonSolid",
                                    label: getDictionaryString("Job.ApplyNow"),
                                    url: applicationFormUrl,
                                    icon: DA_IconNames.ExternalLink,
                                    target: "_blank",
                                },
                            ]}
                            image={{
                                name: positionCategory?.name,
                                url: advertisements?.[0]?.imageUrl,
                                width: 800,
                                height: 600,
                            }}
                            className={S.headline}
                        />
                    )}

                    <DA_Container className={S.container} noVerticalPadding>
                        <main className={S.main} id="main">
                            <strong className={S.introductory}>{props?.props?.introductory}</strong>
                            <div className={S.content}>
                                {advertisements?.map((advertisement, index) => {
                                    const contentWithHtmlBreaks = advertisement.content.replace(
                                        /\\r\\n|\\t/g,
                                        ""
                                    );
                                    return (
                                        <DA_Text
                                            key={index}
                                            html={contentWithHtmlBreaks}
                                            noPadding
                                            className={S.textWrapper}
                                        />
                                    );
                                })}
                            </div>
                            <SocialMediaSharing />
                        </main>

                        <aside className={S.aside}>
                            {isDepartmentValid && areUsersValid && areWorkPlaceCoordinatesValid && (
                                <ContactInfo
                                    department={department}
                                    advertisementUrl={advertisementUrl}
                                    applicationFormUrl={applicationFormUrl}
                                    startDate={formattedDatePart}
                                    startDateASAP={startDateASAP}
                                    applicationDue={applicationDue}
                                    created={created}
                                    users={users}
                                    workPlaceCoordinates={workPlaceCoordinates}
                                    city={city}
                                    country={country}
                                    zip={zip}
                                    address={address}
                                />
                            )}
                        </aside>
                    </DA_Container>

                    {/*Blocks */}
                    {props?.props?.modules?.[0]
                        ? props.props.modules?.map((block, index) => (
                              <DynamicBlock key={index} {...block} />
                          ))
                        : null}
                </>
            ) : null}

            {/** ViewState: EMPTY */}
            {viewState === "EMPTY" ? (
                <DA_Container className={S.container} noVerticalPadding>
                    <main className={S.main} id="main">
                        <DA_PageNotFound code={404} />
                    </main>
                </DA_Container>
            ) : null}

            {/** ViewState: ERROR */}
            {viewState === "ERROR" ? (
                <DA_Container className={S.container} noVerticalPadding>
                    <main className={S.main} id="main">
                        <DA_EmptyState type={DA_EmptyStateTypes.Error} />
                    </main>
                </DA_Container>
            ) : null}
        </main>
    );
};
