import React, { useEffect, useMemo, useState } from "react";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Select } from "@danishagro/shared/src/components/atoms/Select/Select.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Pagination } from "@danishagro/shared/src/components/molecules/Pagination/Pagination.component";
import { DA_Chip } from "@danishagro/shared/src/components/atoms/Chip/Chip.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { Helmet } from "react-helmet";
import { DynamicBlock } from "src/blocks/DynamicBlock.component";
import { isProduction } from "@helpers/isProduction.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { useSessionStorage } from "react-use";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { usePage } from "@contexts/page.context";
import { Card } from "./components/Card/Card.component";
import { JobListPageProps, Position } from "./JobListPage.interface";
import { useItems } from "./hooks/useItems.hook";
import S from "./JobListPage.module.scss";

export const JobListPage = (props: JobListPageProps) => {
    const { currentPathDetails } = usePage();

    const { departments, categories, allPositions, viewState, cities, countries } = useItems();

    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props.props.allowIndexing === true ? "index" : "noindex"},
                ${props.props.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props.props?.allowFollowing, props.props?.allowIndexing]);

    // Elements of jobs per page
    const PAGE_ELEMENTS = 12;

    // Select state
    const [positions, setPositions] = useState<Position[]>(allPositions || []);

    // Pagination state
    const [pageSize, setPageSize] = useState(PAGE_ELEMENTS);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalEntries, setTotalEntries] = useState(positions ? positions?.length : 0);

    const [isFetchingDataFirstTime, setIsFetchingDataFirstTime] = useState(true);

    const [paginatedPositions, setPaginatedPositions] = useState<Position[]>(
        positions?.slice(0, pageSize) || []
    );

    const { getDictionaryString } = useTranslations();
    // Init State for data
    const filtersInitialState = useMemo(
        () => ({
            department: departments?.length === 1 ? departments[0].name : "",
            positionCategory: categories?.length === 1 ? categories[0].name : "",
            // Fix this when cmore countries are available
            countries: "",
            cities: cities?.length === 1 ? cities[0] : "",
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [departments, categories, cities, countries]
    );

    const [filters, setFilters] = useSessionStorage("jobListFilters", filtersInitialState);

    // Select options, add more here
    const options = useMemo(
        () => ({
            department: {
                values: departments,
                displayName: getDictionaryString("JobList.AllDepartments"),
            },
            positionCategory: categories
                ? {
                      values: categories,
                      displayName: getDictionaryString("JobList.AllCategories"),
                  }
                : null,
            cities: cities
                ? {
                      // Added this
                      values: cities,
                      displayName: getDictionaryString("JobList.AllCities"),
                  }
                : null,
            countries: countries
                ? {
                      // Added this
                      values: countries,
                      displayName: getDictionaryString("JobList.AllCountries"),
                  }
                : null,
        }),
        [departments, categories, cities, countries, getDictionaryString]
    );

    // Show display name
    const getDisplayName = (filterKey) => {
        return options[filterKey]?.displayName || getDictionaryString("Job_Items");
    };

    const FILTER_MAP = {
        cities: "city",
        countries: "country",
        department: "department",
        positionCategory: "positionCategory",
    };

    // Filter logic
    useEffect(() => {
        let filteredPositions = allPositions;

        Object.keys(filters).forEach((filterKey) => {
            const filterValue = filters[filterKey];
            if (filterValue) {
                const positionAttribute = FILTER_MAP[filterKey];
                if (positionAttribute) {
                    if (["cities", "countries"].includes(filterKey)) {
                        // Direct attributes
                        filteredPositions = filteredPositions?.filter(
                            (position) => position[positionAttribute] === filterValue
                        );
                    } else {
                        // Nested attributes (assuming they have a 'name' property)
                        filteredPositions = filteredPositions?.filter(
                            (position) => position[positionAttribute]?.name === filterValue
                        );
                    }
                }
            }
        });

        setPositions(filteredPositions);
        setIsFetchingDataFirstTime(false);
        setPageNumber(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, allPositions]);

    // Reset button
    const resetSelections = () => {
        // Save current scroll position
        setFilters(filtersInitialState);
    };

    // Calculate if any filter is selected
    const isAnyFilterSelected = useMemo(() => {
        return Object.values(filters).some((filter) => filter !== "");
    }, [filters]);

    // Update paginated positions when positions or pageNumber changes
    useEffect(() => {
        if (positions) {
            const start = pageNumber * pageSize;
            const end = start + pageSize;
            setPaginatedPositions(positions?.slice(start, end));
        } else {
            setPaginatedPositions([]);
        }
    }, [positions, pageNumber, pageSize]);

    // Update total entries
    useEffect(() => {
        if (positions) {
            setTotalEntries(positions?.length);
        } else {
            setTotalEntries(0);
        }
    }, [positions]);

    const heroBlocks = useMemo(() => {
        return props.props?.hero?.[0]
            ? props.props?.hero?.map((block, index) => <DynamicBlock key={index} {...block} />)
            : null;
    }, [props.props?.hero]);

    const moduleBlocks = useMemo(() => {
        return props.props?.modules?.[0]
            ? props.props.modules?.map((block, index) => <DynamicBlock key={index} {...block} />)
            : null;
    }, [props.props?.modules]);

    if (isFetchingDataFirstTime) {
        return <DA_Spinner />;
    }

    return (
        <main id="main">
            <Helmet>
                <title>{props.props?.metaTitle || props.name}</title>
                <meta name="description" content={props.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
            </Helmet>

            {/* Breadcrumbs */}
            <DA_Container noVerticalPadding>
                <DA_Breadcrumb
                    breadcrumbItems={[
                        ...currentPathDetails.map(({ name, path }) => ({
                            title: name,
                            href: path,
                        })),
                    ]}
                    lastIsLink={true}
                />
            </DA_Container>

            <DA_Container noVerticalPadding>
                <div className={S.inner}>
                    {heroBlocks}
                    <div className={S.subTextIntro}>{moduleBlocks}</div>
                    {/** Filter */}
                    {viewState === "DONE" ? (
                        <>
                            {options && (
                                <div className={S.selectWrapper}>
                                    {Object.keys(filters).map((filterKey) => {
                                        // Skip if the key is not in the options object
                                        if (!options[filterKey]) {
                                            return null;
                                        }
                                        const displayName = getDisplayName(filterKey);

                                        const currentOptions = options?.[filterKey]?.values?.filter(
                                            (option) => {
                                                return option.trim() !== "";
                                            }
                                        );

                                        // If only one option is available, don't render the select
                                        // Countries not shown because only 1 is available
                                        if (currentOptions?.length <= 1) {
                                            return null;
                                        }

                                        return (
                                            <div key={filterKey}>
                                                <DA_Select
                                                    id={`${filterKey}-select`}
                                                    fullWidth={true}
                                                    value={filters[filterKey]}
                                                    onChange={(selection) =>
                                                        setFilters({
                                                            ...filters,
                                                            [filterKey]: selection.value,
                                                        })
                                                    }
                                                    options={[
                                                        { value: "", label: `${displayName}` },
                                                        ...(currentOptions?.map((option) => ({
                                                            value: option,
                                                            label: option,
                                                        })) || []),
                                                    ]}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </>
                    ) : null}
                    {/** Chip */}
                    <div className={S.filterOptionsWrapper}>
                        {filters &&
                            Object.entries(filters).map(([key, value]) =>
                                value ? (
                                    <DA_Chip
                                        key={key}
                                        className={S.removeChip}
                                        label={`${getDictionaryString(`JobList.${key}`)}: ${value}`}
                                        removable
                                        onRemove={() => {
                                            setFilters({ ...filters, [key]: "" });
                                        }}
                                    />
                                ) : null
                            )}

                        {isAnyFilterSelected && (
                            <DA_Button
                                className={S.resetButton}
                                onClick={resetSelections}
                                title={getDictionaryString("JobList.ResetFilterText")}
                                fullWidth={false}
                                small={true}
                                isGhost={true}
                            />
                        )}
                    </div>

                    <div>
                        {/** EMPTY FILTERED */}
                        {viewState === "DONE" && !positions?.[0] && allPositions?.[0] ? (
                            <div className={S.noPositionsMessage}>
                                <DA_Icon
                                    name={DA_IconNames.ExclamationMark}
                                    useSvgDimensions={true}
                                    className={S.noPositionsIcon}
                                />
                                <DA_Title h3>
                                    {getDictionaryString("JobList.TryDifferentFilters")}
                                </DA_Title>
                                <DA_Text>{getDictionaryString("JobList.NoResults")}</DA_Text>
                            </div>
                        ) : null}

                        {/** EMPTY ALL */}
                        {viewState === "EMPTY" && !allPositions?.[0] ? (
                            <div className={S.noPositionsMessage}>
                                <DA_EmptyState
                                    type={DA_EmptyStateTypes.NoResults}
                                    title={getDictionaryString("JobList.NoPositions")}
                                    text={getDictionaryString("JobList.GoToMainPageText")}
                                />
                                <DA_Button
                                    className={S.linkToAll}
                                    href={props?.props?.noJobsRedirect?.url || "/"}
                                    title={getDictionaryString("Joblist.SeeAllAvailablePositions")}
                                />
                            </div>
                        ) : null}

                        {/** DONE */}
                        {viewState === "DONE" && positions?.[0] ? (
                            <div className={S.jobListWrapper}>
                                {paginatedPositions?.map((position, index) => (
                                    <Card key={index} {...position} />
                                ))}
                            </div>
                        ) : null}

                        {/** LOADING */}
                        {viewState === "LOADING" ? (
                            <div className={S.spinnerWrapper}>
                                <DA_Spinner className={S.spinner} />
                            </div>
                        ) : null}

                        {/** ERROR */}
                        {viewState === "ERROR" ? (
                            <div className={S.spinnerWrapper}>
                                <DA_EmptyState type={DA_EmptyStateTypes.Error} />
                            </div>
                        ) : null}
                    </div>

                    {positions?.length > 0 && (
                        <DA_Pagination
                            className={S.pagination}
                            hideBatchSize={true}
                            batchSize={pageSize}
                            onChangeBatchSize={(newBatchSize, newOffset) => {
                                setPageSize(newBatchSize);
                                setPageNumber(newOffset / newBatchSize);
                            }}
                            offset={pageNumber * pageSize}
                            totalItems={totalEntries}
                            onChangePage={({ offset: newOffset }) => {
                                setPageNumber(Math.floor(newOffset / pageSize));
                            }}
                            hideContent={isFetchingDataFirstTime}
                            texts={{
                                showing: "Showing",
                                of: "of",
                            }}
                        />
                    )}
                </div>
            </DA_Container>
        </main>
    );
};
