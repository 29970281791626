import React from "react";
import { B2B_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useSiteHeader } from "@danishagro/shared/src/contexts/siteHeader.context";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { getB2bImage } from "@danishagro/shared/src/helpers/getB2bImage.helper";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { useTranslations } from "../../../../contexts/translations/translations.context";
import { getRelativeRootOnLocalhost } from "../../../../helpers/getRelativeUrlOnLocalhost.helper";
import { useAppData } from "../../../../contexts/appData.context";
import S from "./MiniCart.module.scss";

export const DA_MiniCartPopUp = () => {
    const { getDictionaryItem, getDictionaryString } = useTranslations();
    const { currentCulture, cvrAndCustomerNumbers, customerNumber } = useAppData();

    const {
        headerMeasure,
        cartButtonMeasure,
        farmInTimeButtonMeasure,
        accountButtonMeasure,
        togglePopUp,
    } = useSiteHeader();

    const { isMobileOrTablet } = useScreen();
    const { orderLines, isLoadingCart } = useCart();

    const { dismissBottomSheet } = useBottomSheet();

    const hasOrderLines = orderLines.length > 0;
    const cartUrl = `${getRelativeRootOnLocalhost(B2B_SITE_ORIGIN)}/cart`;

    const onCloseButtonClicked = () => {
        dismissBottomSheet();
        togglePopUp(undefined);
    };

    return (
        <div
            className={S.miniCart}
            style={{
                maxHeight:
                    !isMobileOrTablet && cartButtonMeasure
                        ? `calc(100vh - ${cartButtonMeasure.top + cartButtonMeasure.bottom}px)`
                        : undefined,
                right:
                    !isMobileOrTablet &&
                    farmInTimeButtonMeasure &&
                    accountButtonMeasure &&
                    headerMeasure
                        ? `${
                              farmInTimeButtonMeasure.width +
                              accountButtonMeasure.width +
                              headerMeasure.left
                          }px`
                        : undefined,
                top: !isMobileOrTablet && cartButtonMeasure ? 0 : undefined,
            }}
        >
            <div className={S.content}>
                {!isMobileOrTablet ? (
                    <div className={S.header}>
                        {/** Header */}
                        <h2 className={S.title}>
                            {getDictionaryItem("miniCartTitle", {
                                uppercaseFirst: true,
                            })}
                        </h2>

                        {cvrAndCustomerNumbers && customerNumber ? (
                            <div className={S.account}>
                                {cvrAndCustomerNumbers.firstName} {cvrAndCustomerNumbers.lastName} (
                                {customerNumber})
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {isMobileOrTablet && cvrAndCustomerNumbers && customerNumber ? (
                    <div className={S.accountMobile}>
                        {cvrAndCustomerNumbers.firstName} {cvrAndCustomerNumbers.lastName} (
                        {customerNumber})
                    </div>
                ) : null}

                {isLoadingCart && (
                    <DA_EmptyState
                        type={DA_EmptyStateTypes.Loading}
                        title={getDictionaryString("loading cart")}
                    />
                )}

                {/** Order lines */}
                {!isLoadingCart && orderLines?.length > 0 && (
                    <table className={S.table}>
                        <thead className={S.head}>
                            <tr>
                                <th colSpan={2}>
                                    {getDictionaryItem("product", {
                                        uppercaseFirst: true,
                                    })}
                                </th>
                                <th>
                                    {getDictionaryItem("amount", {
                                        uppercaseFirst: true,
                                    })}
                                </th>
                            </tr>
                        </thead>
                        <tbody className={S.body}>
                            {orderLines.map((orderLine) => {
                                const image = getB2bImage(orderLine.image, 60);
                                return (
                                    <tr key={orderLine.id}>
                                        <td className={S.imageCell}>
                                            <DA_BasicLink href={orderLine.url}>
                                                <DA_Image
                                                    src={image.url}
                                                    alt=""
                                                    width={image.width}
                                                    height={image.height}
                                                    className={S.image}
                                                />
                                            </DA_BasicLink>
                                        </td>

                                        <td>
                                            <DA_BasicLink
                                                href={orderLine.url}
                                                className={S.productName}
                                            >
                                                {orderLine.productName}
                                            </DA_BasicLink>
                                        </td>
                                        <td className={S.noWrap}>
                                            {formatNumber(orderLine.quantity, currentCulture)}{" "}
                                            {getDictionaryItem("pcs")}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}

                {hasOrderLines || (
                    <div className={S.noProductsText}>
                        {getDictionaryItem("MiniBasketNoProducts")}
                    </div>
                )}
            </div>

            {/** Submit */}
            <div className={S.buttonWrapper}>
                <DA_Button
                    href={cartUrl}
                    title={getDictionaryString("goToCart")}
                    onClick={onCloseButtonClicked}
                    target="_self"
                    className={S.button}
                    disabled={!hasOrderLines}
                />
            </div>
        </div>
    );
};
