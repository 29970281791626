import React, { MouseEvent } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import {
    B2B_SITE_ORIGIN,
    CMS_SITE_ORIGIN,
    MYFARM_SITE_ORIGIN,
} from "@danishagro/shared/src/constants/urlRoot.constants";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import S from "./Tabs.module.scss";

export const Tabs = () => {
    const { currentSite } = useAppData();

    const cmsActiveClass = currentSite === "CMS" ? S.isActive : undefined;
    const b2bActiveClass = currentSite === "B2B" ? S.isActive : undefined;
    const myfarmActiveClass = currentSite === "MYFARM" ? S.isActive : undefined;

    const onTabClicked = (event: MouseEvent<HTMLButtonElement>, origin: string) => {
        event?.preventDefault();

        window.location.href = `${origin}/`;
    };

    return (
        <div className={S.wrapper}>
            <div className={S.inner}>
                <button
                    className={cn(S.tabButton, cmsActiveClass)}
                    onClick={(event) => onTabClicked(event, CMS_SITE_ORIGIN)}
                >
                    Viden & Værdi
                </button>
                <button
                    className={cn(S.tabButton, b2bActiveClass)}
                    onClick={(event) => onTabClicked(event, B2B_SITE_ORIGIN)}
                >
                    Webshop
                </button>
                <button
                    className={cn(S.tabButton, myfarmActiveClass)}
                    onClick={(event) => onTabClicked(event, MYFARM_SITE_ORIGIN)}
                >
                    Mit Landbrug
                </button>
            </div>
        </div>
    );
};
