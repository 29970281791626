import React, { ForwardedRef, useEffect, useMemo } from "react";
import { useMeasure } from "react-use";
import { useLocation } from "react-router-dom";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { B2B_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";
import { getLoginLink } from "@danishagro/shared/src/helpers/getLoginLink.helper";
import { useScreen } from "../../../../contexts/screen.context";
import { SiteHeaderPopUpType, useSiteHeader } from "../../../../contexts/siteHeader.context";
import { DA_Icon, DA_IconNames } from "../../../atoms/Icon/Icon.component";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_Badge } from "../Badge/Badge.component";
import { useCart } from "../../../../contexts/cart/cart.context";
import { useTranslations } from "../../../../contexts/translations/translations.context";
import { getRelativeRootOnLocalhost } from "../../../../helpers/getRelativeUrlOnLocalhost.helper";
import { useAppData } from "../../../../contexts/appData.context";
import S from "./ServiceButtons.module.scss";

export const DA_ServiceButtons = React.forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
    const { pathname } = useLocation();

    const isFarmInTimePage = useMemo(() => pathname.indexOf("bestillingsliste") >= 0, [pathname]);

    const { getDictionaryString } = useTranslations();
    const { cvrAndCustomerNumbers, customerNumber, currentSite } = useAppData();

    const { isMobileOrTablet } = useScreen();
    const {
        setCartButtonMeasure,
        setFarmInTimeButtonMeasure,
        setAccountButtonMeasure,
        showMiniCart,
        showAccountPicker,
        togglePopUp,
    } = useSiteHeader();
    const { orderLines, isLoadingCart } = useCart();
    const [cartButtonRef, cartButtonRect] = useMeasure<HTMLButtonElement>();
    const [farmInTimeButtonRef, farmInTimeButtonRect] = useMeasure<HTMLAnchorElement>();
    const [accountButtonRef, accountButtonRect] = useMeasure<HTMLButtonElement>();

    const totalQuantity = useMemo(() => {
        if (orderLines?.[0]) {
            return orderLines.reduce((total, line) => total + line.quantity, 0);
        }

        return 0;
    }, [orderLines]);

    const orderListUrl = `${getRelativeRootOnLocalhost(B2B_SITE_ORIGIN)}/bestillingsliste`;

    // NOTE: Removed for now because notifications isn't ready for launch.
    // const { viewState, notificationCount } = useNotifications();
    const hasOpenMenu = showMiniCart || showAccountPicker;

    useEffect(() => {
        setCartButtonMeasure(cartButtonRect);
        setFarmInTimeButtonMeasure(farmInTimeButtonRect);
        setAccountButtonMeasure(accountButtonRect);
    }, [
        cartButtonRect,
        farmInTimeButtonRect,
        accountButtonRect,
        setCartButtonMeasure,
        setFarmInTimeButtonMeasure,
        setAccountButtonMeasure,
    ]);

    return (
        <div className={S.serviceButtons} ref={ref}>
            {/** Cart */}
            <button
                type="button"
                onClick={() => togglePopUp(SiteHeaderPopUpType.MiniCart)}
                className={cn(S.serviceButton, showMiniCart && S.active)}
                ref={cartButtonRef}
                aria-label={getDictionaryString("accessibilityButtonCart")}
            >
                <DA_Icon name={DA_IconNames.Cart} className={S.icon} />

                {(totalQuantity > 0 || isLoadingCart) && (
                    <DA_Badge
                        size="small"
                        text={totalQuantity || ""}
                        className={S.buttonBadge}
                        showSpinner={isLoadingCart}
                    />
                )}
            </button>

            {/** Farm in Time */}
            {cvrAndCustomerNumbers && customerNumber && (
                <DA_BasicLink
                    ref={farmInTimeButtonRef}
                    href={orderListUrl}
                    target="_self"
                    className={cn(S.serviceButton, isFarmInTimePage && !hasOpenMenu && S.active)}
                    onClick={() => togglePopUp(undefined)}
                    ariaLabel={getDictionaryString("accessibilityFarmInTimeLinkText")}
                >
                    <DA_Icon name={DA_IconNames.FormCheck} className={S.icon} />
                </DA_BasicLink>
            )}

            {/** Account: Logged Out */}
            {!globalThis.isAuthenticated && (
                <DA_BasicLink
                    href={getLoginLink(currentSite)}
                    target="_self"
                    className={cn(S.serviceButton, S.user)}
                    ariaLabel={getDictionaryString("accessibilityMobileLogin")}
                >
                    <div className={S.buttonIconWrapper}>
                        <DA_Icon name={DA_IconNames.UserLoggedOut} className={S.icon} />
                    </div>
                    {getDictionaryString("loginTitle")}
                </DA_BasicLink>
            )}

            {/** Account: Logged In */}
            {!isMobileOrTablet && globalThis.isAuthenticated && cvrAndCustomerNumbers ? (
                <button
                    type="button"
                    onClick={() => togglePopUp(SiteHeaderPopUpType.AccountPicker)}
                    className={cn(S.serviceButton, S.user, showAccountPicker && S.active)}
                    ref={accountButtonRef}
                    title={getDictionaryString("account picker")}
                >
                    <div className={S.buttonIconWrapper}>
                        <DA_Icon name={DA_IconNames.UserLoggedIn} className={S.icon} />

                        {/* NOTE: Removed for now because notifications isn't ready for launch. */}
                        {/* {viewState === "DONE" && notificationCount > 0 && (
                            <DA_Badge
                                size="small"
                                text={notificationCount}
                                className={S.buttonBadge}
                            />
                        )} */}
                    </div>
                    {cvrAndCustomerNumbers.firstName} {cvrAndCustomerNumbers.lastName}
                    {customerNumber && ` (${customerNumber})`}
                </button>
            ) : null}
        </div>
    );
});

DA_ServiceButtons.displayName = "DA_ServiceButtons";
