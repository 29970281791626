import React, { useCallback, useEffect, useRef, useState } from "react";
import { floorToMultipleOf } from "@baggie/core";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Select } from "../../atoms/Select/Select.component";
import S from "./Pagination.module.scss";

const MIN_BATCH_SIZE_DEFAULT = 5;
const MAX_PAGE_BUTTONS = 5;

export interface DA_PaginationProps {
    batchSize: number;
    onChangeBatchSize: (batchSize: number, offset: number) => void;
    offset: number;
    totalItems: number;
    texts: {
        showing: React.ReactNode;
        of: React.ReactNode;
    };
    itemDefinition?: React.ReactNode;
    onChangePage: ({ pageIndex, offset }: { pageIndex: number; offset: number }) => void;
    hideContent?: boolean;
    disabled?: boolean;
    className?: string;
    hideBatchSize?: boolean;
}

export const DA_Pagination = ({
    batchSize,
    onChangeBatchSize,
    offset,
    totalItems,
    texts,
    itemDefinition,
    onChangePage,
    hideContent,
    disabled,
    className,
    hideBatchSize = false,
}: DA_PaginationProps) => {
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [amountOptions, setAmountOptions] = useState([]);
    const minBatchSize = useRef(Math.min(MIN_BATCH_SIZE_DEFAULT, batchSize));

    const changeBatchSize = useCallback(
        ({ value }) => {
            const newBatchSize = parseInt(value, 10);
            if (newBatchSize !== batchSize) {
                onChangeBatchSize(newBatchSize, floorToMultipleOf(offset, newBatchSize));
            }
        },
        [batchSize, onChangeBatchSize, offset]
    );

    const renderPageItem = useCallback(
        (index) => (
            <li key={index} className={S.page}>
                <button
                    type="button"
                    onClick={() =>
                        onChangePage({
                            pageIndex: index,
                            offset: (index + 1) * batchSize - batchSize,
                        })
                    }
                    className={cn(S.pageButton, index === currentPage && S.active)}
                    disabled={disabled}
                >
                    {index + 1}
                </button>
            </li>
        ),
        [onChangePage, batchSize, currentPage, disabled]
    );

    const renderPages = useCallback(() => {
        const mpb = MAX_PAGE_BUTTONS / 2;

        if (pages.length <= MAX_PAGE_BUTTONS) {
            // Show all pages
            return pages.map((page) => renderPageItem(page));
        } else if (currentPage <= mpb) {
            // Show first pages and the very last
            return [
                pages.slice(0, MAX_PAGE_BUTTONS - 1).map((page) => renderPageItem(page)),
                <li key="dots-after" className={S.page}>
                    ...
                </li>,
                renderPageItem(pages[pages.length - 1]),
            ];
        } else if (currentPage >= pages.length - mpb - 1) {
            // Show first pages and the very last
            return [
                renderPageItem(pages[0]),
                <li key="dots-before" className={S.page}>
                    ...
                </li>,
                pages
                    .slice(pages.length - MAX_PAGE_BUTTONS + 1, pages.length)
                    .map((page) => renderPageItem(page)),
            ];
        }
        return [
            renderPageItem(pages[0]),
            <li key="dots-before" className={S.page}>
                ...
            </li>,
            pages.slice(currentPage - 1, currentPage + 2).map((page) => renderPageItem(page)),
            <li key="dots-after" className={S.page}>
                ...
            </li>,
            renderPageItem(pages[pages.length - 1]),
        ];
    }, [pages, currentPage, renderPageItem]);

    useEffect(() => {
        const offsetMax = Math.min(offset, totalItems - 1);
        setPages(new Array(Math.ceil(totalItems / batchSize)).fill("").map((_, index) => index));
        setCurrentPage(Math.floor(offsetMax >= totalItems ? -1 : offsetMax / batchSize));
    }, [totalItems, batchSize, offset]);

    useEffect(() => {
        const amountLength = Math.ceil(totalItems / 10);
        let newAmountOptions = new Array(amountLength)
            .fill("")
            .map((_, index) => (index + 1 === amountLength ? totalItems : (index + 1) * 10))
            .slice(0, 10);
        newAmountOptions.push(minBatchSize.current, batchSize);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newAmountOptions = [...new Set(newAmountOptions)].filter((x) => x <= totalItems);
        newAmountOptions.sort((a, b) => a - b);
        setAmountOptions(newAmountOptions.map((value) => ({ value })));
    }, [totalItems, batchSize]);

    return totalItems > minBatchSize.current ? (
        <div className={cn(S.wrapper, className)}>
            <div className={cn(S.placeholderLeft, hideContent && S.hidden)} />
            {!hideBatchSize && (
                <div className={cn(S.batchSize, hideContent && S.hidden)}>
                    {texts.showing}{" "}
                    <DA_Select
                        small
                        value={
                            amountOptions.length
                                ? Math.min(batchSize, amountOptions.at(-1)?.value)
                                : batchSize
                        }
                        onChange={changeBatchSize}
                        options={amountOptions}
                        disabled={disabled}
                        getValue={({ value }) => value}
                        getLabel={({ value }) => value}
                    />{" "}
                    {texts.of} {totalItems} {itemDefinition}
                </div>
            )}

            <div className={cn(S.pageListWrapper, hideContent && S.hidden)}>
                {pages.length > 1 && <ul className={S.pageList}>{renderPages()}</ul>}
            </div>
        </div>
    ) : null;
};
