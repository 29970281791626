import React, { useMemo } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { format } from "date-fns";
import { ContactInfoProps } from "./ContactInfo.interface";
// Import style
import S from "./ContactInfo.module.scss";

export const ContactInfo = ({
    workPlaceCoordinates,
    department,
    applicationDue,
    startDate,
    startDateASAP,
    users,
    city,
    country,
    zip,
    address,
}: ContactInfoProps) => {
    const GOOGLE_MAPS_API_KEY = "AIzaSyCBqY9mpYO6gk6YIWTeJbIev-lFSux2WOM";
    const ZOOM_LEVEL = 15;
    const MAP_WIDTH = 440;
    const MAP_HEIGHT = 145;

    const { isMobileOrTablet } = useScreen();

    // Coordinates
    const coordinates = workPlaceCoordinates?.split(", ");
    const [latitude, longitude] = coordinates || [null, null];

    const { getDictionaryString } = useTranslations();

    // Check date
    function checkDate(dateString, asap = false) {
        if (dateString === "01/01/0001 00:00:00" || asap === true) {
            return <span>{getDictionaryString("Job.AsSoonAsPossible")}</span>;
        }
        return <span>{dateString}</span>;
    }

    const participant = users?.projectParticipants?.[0];

    //applicationDate
    const formattedApplicationDueDate = useMemo(() => {
        if (applicationDue) {
            const formattedDate = format(new Date(applicationDue), "dd.MM.yyyy");
            return formattedDate;
        }
    }, [applicationDue]);

    //StartDate
    const formattedStartDate = useMemo(() => {
        if (startDate) {
            const formattedDate = format(new Date(startDate), "dd.MM.yyyy");
            return formattedDate;
        }
    }, [startDate]);

    return (
        <div className={S.extraInfoWrapper}>
            <div className={S.extraBlock}>
                {latitude && longitude && (
                    <img
                        src={`https://maps.googleapis.com/maps/api/staticmap?%20center=${latitude},${longitude}&${
                            isMobileOrTablet ? "scale=2" : "scale=1"
                        }&zoom=${ZOOM_LEVEL}&size=${MAP_WIDTH}x${MAP_HEIGHT}%20&markers=${latitude},${longitude}%20&key=${GOOGLE_MAPS_API_KEY}`}
                        alt="Google maps"
                    />
                )}
            </div>
            <div className={S.extraBlock}>
                <span className={S.title}>{getDictionaryString("Job.Workplace")}</span>
                {department.name && <span className={S.info}>{department.name}</span>}
                {address && <span className={S.info}>{address},</span>}
                {(zip || city) && (
                    <span className={S.info}>
                        {zip && city ? `${zip} ${city}` : zip ? zip : city},
                    </span>
                )}
                {country && <span className={S.info}>{country}</span>}
            </div>

            {formattedApplicationDueDate ? (
                <div className={S.extraBlock}>
                    <span className={S.title}>
                        {getDictionaryString("Job.ApplicationDeadline")}
                    </span>
                    <span className={S.info}>{checkDate(formattedApplicationDueDate)}</span>
                </div>
            ) : null}

            {startDate ? (
                <div className={S.extraBlock}>
                    <span className={S.title}>{getDictionaryString("Job.StartDate")}</span>
                    <span className={S.info}>{checkDate(formattedStartDate, startDateASAP)}</span>
                </div>
            ) : null}

            <div className={S.extraBlock}>
                {participant && (
                    <>
                        <span className={S.info}>
                            {getDictionaryString("Job.PositionQuestions")}
                        </span>
                        <div className={S.extraInfoContactBox}>
                            <div className={S.extraInfoContactBoxLeft}>
                                <img src={participant?.imageUrl} alt="Contact person" />
                            </div>
                            <div className={S.extraInfoContactBoxRight}>
                                <span className={S.title}>
                                    {participant?.firstName} {participant?.lastName}
                                </span>
                                <span className={S.jobPosition}>{participant?.title}</span>
                                <div className={S.extraInfoContactBoxRightReach}>
                                    <span className={S.info}>
                                        <a href={`mailto:${participant?.email}`}>
                                            {participant?.email}
                                        </a>
                                    </span>
                                    <span className={S.info}>
                                        {participant?.phone ? (
                                            <>
                                                {getDictionaryString("Job.Phone")}:{" "}
                                                <a href={`tel:${participant?.phone}`}>
                                                    {participant?.phone}
                                                </a>
                                            </>
                                        ) : participant?.phoneMobile ? (
                                            <>
                                                {getDictionaryString("Job.Mobile")}:{" "}
                                                <a href={`tel:${participant?.phoneMobile}`}>
                                                    {participant?.phoneMobile}
                                                </a>
                                            </>
                                        ) : null}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
