import React, { Fragment } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { getLoginLink } from "@danishagro/shared/src/helpers/getLoginLink.helper";
import { DA_Badge } from "../Badge/Badge.component";
import { DA_BasicLink } from "../../../atoms/BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "../../../atoms/Icon/Icon.component";
import { useScreen } from "../../../../contexts/screen.context";
import { useAppData } from "../../../../contexts/appData.context";
import { useTranslations } from "../../../../contexts/translations/translations.context";
import { useAccountButton } from "./hooks/useAccountButton";
import { useCartButton } from "./hooks/useCartButton";
import { useFarmInTimeButton } from "./hooks/useFarmInTimeButton";
import { useSearchButton } from "./hooks/useSearchButton";
import S from "./Toolbar.module.scss";

type DA_ToolbarProps = {
    isLoginOptionsTrue?: boolean;
};

export const DA_Toolbar = ({ isLoginOptionsTrue }: DA_ToolbarProps) => {
    const { isMobileOrTablet } = useScreen();
    const { currentSite, isAppFunctional } = useAppData();
    const { getDictionaryString } = useTranslations();

    // Cart
    const { showCart, onCartButtonClicked, cartBadge, isCartMenuActive, isLoadingCart } =
        useCartButton();
    const isCartMenuActiveClass = isCartMenuActive ? S.isActive : undefined;

    // Farm In Time
    const { showFarmInTime, farmInTimeLink, isFarmInTimeMenuActive } = useFarmInTimeButton();
    const isFarmInTimeMenuActiveClass = isFarmInTimeMenuActive ? S.isActive : undefined;

    // Account
    const { onAccountButtonClicked, accountBadge, isAccountMenuActive } = useAccountButton();
    const isAccountMenuActiveClass = isAccountMenuActive ? S.isActive : undefined;

    // Search
    const { showSearch, onSearchButtonClicked, isSearchMenuActive } = useSearchButton();
    const isSearchMenuActiveClass = isSearchMenuActive ? S.isActive : undefined;

    return (
        <Fragment>
            {isMobileOrTablet ? (
                <div className={S.toolbar}>
                    <div className={S.inner}>
                        {/** Cart */}
                        {isLoginOptionsTrue && showCart ? (
                            <button
                                onClick={onCartButtonClicked}
                                className={cn(S.button, isCartMenuActiveClass)}
                                aria-label={getDictionaryString("accessibilityButtonCart")}
                            >
                                <DA_Icon name={DA_IconNames.CartWeighted} className={S.icon} />

                                {/** Quantity Badge */}
                                {isLoadingCart ? (
                                    <DA_Badge text="" className={S.badge} showSpinner />
                                ) : cartBadge > 0 ? (
                                    <DA_Badge text={cartBadge} className={S.badge} />
                                ) : null}
                            </button>
                        ) : null}

                        {/** Farm In Time */}
                        {isLoginOptionsTrue && showFarmInTime ? (
                            <DA_BasicLink
                                href={farmInTimeLink}
                                target="_self"
                                className={cn(S.button, isFarmInTimeMenuActiveClass)}
                                ariaLabel={getDictionaryString("accessibilityFarmInTimeLinkText")}
                            >
                                <DA_Icon name={DA_IconNames.FormCheckWeighted} className={S.icon} />
                            </DA_BasicLink>
                        ) : null}

                        {/** Account: Logged Out */}
                        {isLoginOptionsTrue && !globalThis.isAuthenticated ? (
                            <DA_BasicLink
                                href={getLoginLink(currentSite)}
                                target="_self"
                                className={S.button}
                                ariaLabel={getDictionaryString("accessibilityMobileLogin")}
                            >
                                <DA_Icon
                                    name={DA_IconNames.UserLoggedOutWeighted}
                                    className={S.icon}
                                />
                            </DA_BasicLink>
                        ) : null}

                        {/** Account: Logged In */}
                        {isLoginOptionsTrue && globalThis.isAuthenticated ? (
                            <button
                                onClick={onAccountButtonClicked}
                                className={cn(S.button, isAccountMenuActiveClass)}
                                aria-label={getDictionaryString("account picker")}
                            >
                                <DA_Icon
                                    name={DA_IconNames.UserLoggedInWeighted}
                                    className={S.icon}
                                />

                                {/** Notifications Badge */}
                                {accountBadge > 0 ? (
                                    <DA_Badge text={accountBadge} className={S.badge} />
                                ) : null}
                            </button>
                        ) : null}

                        {/** Search */}
                        {showSearch ? (
                            <button
                                onClick={onSearchButtonClicked}
                                className={cn(
                                    S.button,
                                    isSearchMenuActiveClass,
                                    !isLoginOptionsTrue && S.searchFullWidthBtn
                                )}
                                aria-label={getDictionaryString("accessibilityButtonSearch")}
                            >
                                <DA_Icon name={DA_IconNames.ZoomWeighted} className={S.icon} />
                            </button>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};
